import { useContext, useState } from "react";
import AuthContext from "../../../../../../context/AuthContext";
import { TControlContext } from "../../hooks/useTControl";
import { TControlSelect } from "../../../../utils/TControlSelect/TControlSelect";
import { TControlButton } from "../../../../utils/TControlButton/TControlButton";
import { postDynamicValues } from "../../../../http/http";

export let InputOperParam = () => {
  const { authTokens } = useContext(AuthContext);

  let {
    memoizedBoilersHouses,
    memoizedDate,
    memoizedSmena,
    memoizedErenergyResource
  } = useContext(TControlContext)

  let [dynamicValuesPost, setDynamicValuesPost] = useState({});
  let [coolantActive, setCoolantActive] = useState(false);

  const fillForm = (e, getter, setter, pk) => {
    let name = e.target.name;
    let value = e.target.value;
    if (e.target.type === "number") value = parseFloat(value)
    setter({ ...getter, [name]: value })
  }

  let createPostDynamicValues = (e) => {
    // console.log('open modal', e);
    e.preventDefault();
    // handleOpenModal()
    postDynamicValues(authTokens, dynamicValuesPost, memoizedSmena?.activeSmena?.[0]?.pk, memoizedBoilersHouses?.selecterBoilersHouse)
  }


  return (
    <div style={{ marginTop: '50px' }}>
      <form className='hydrologist_worker-form'>
        <TControlSelect
          name={"Выберите котельную"}
          list={memoizedBoilersHouses?.boilersHouses}
          value={'pk'}
          visible_value={'address'}
          getter={memoizedBoilersHouses?.selecterBoilersHouse}
          setter={memoizedBoilersHouses?.setSelecterBoilersHouse}
          parse={'int'}
          defaultValue={memoizedBoilersHouses?.selecterBoilersHouse}
        />
      </form>

      <form onSubmit={createPostDynamicValues}>
        <h5 className="tcontrol__dynamicValues-title">Теплоноситель</h5>
        <div className="row row-cols-4">
          <div className="col">
            <label className="form-label">Температура подачи в сеть</label>
            <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="t1" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)}  value={dynamicValuesPost.t1} placeholder="T1" />
          </div>
          {/* <div className="col">
            <label className="form-label">Давление подачи теплоносителя</label>
            <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="p1" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)}  placeholder="P1" />
          </div> */}
          <div className="col">
            <label className="form-label">Обратка температура</label>
            <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="t2" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)}  placeholder="T2" />
          </div>
          {/* <div className="col">
            <label className="form-label">Обратка давление</label>
            <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="p2" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)}  placeholder="P2" />
          </div> */}
        </div>
        <h5 className="tcontrol__dynamicValues-title">ГВС</h5>
        <div className="row row-cols-4">
          <div className="col">
            <label className="form-label">Температура подачи в сеть</label>
            <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="t3" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="T3" required />
          </div>
          {/* <div className="col">
            <label className="form-label">Давление подачи теплоносителя</label>
            <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="p3" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="P3" required />
          </div> */}
          <div className="col">
            <label className="form-label">Обратка температура</label>
            <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="t4" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="T4" required />
          </div>
          {/* <div className="col">
            <label className="form-label">Обратка давление</label>
            <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="p4" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="P4" required />
          </div> */}
        </div>
        <h5 className="tcontrol__dynamicValues-title">Остальное</h5>
        <div className="row row-cols-4" style={{ marginBottom: '20px' }}>
          {/* <div className="col">
            <label className="form-label">Давление воды на вводе</label>
            <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="pressure" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="Pвх" required />
          </div> */}
          <div className="col">
            <label className="form-label">Давление газа на вводе</label>
            <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="gas" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="Газ" required />
          </div>
          {/* <div className="col">
            <label className="form-label">Примечание</label>
            <input className="form-control tcontrol__input" type="text" name="note" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} />
          </div> */}
        </div>
        <TControlButton
          title='Сохранить'
          type='submit' />
      </form>

    </div>
  )
}