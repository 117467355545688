import { useContext, useState } from "react";
import AuthContext from "../../../../context/AuthContext";
import { TControlContext } from "./hooks/useTControl";
import { TControlInput } from "../../utils/TControlInput/TControlInput";
import { TControlButton } from "../../utils/TControlButton/TControlButton";
import { useForm } from "react-hook-form";
import { postSmena } from "../../http/http";
import { RSODynamicButtonGroup } from "../../../RSODynamic/utils/RSODynamicButtonGroup/RSODynamicButtonGroup";
import { DayData } from "./components/dayData/dayData";
import { PrintMonthData } from "./components/printMonthData/printMonthData";
import { CloseSmena } from "./components/closeSmena/closeSmena";
import { OperParam } from "./components/operParam/operParam";
import { InputOperParam } from "./components/inputOperParam/inputOperParam";

export const TControlWorker = () => {
  const { authTokens } = useContext(AuthContext);

  let {
    memoizedBoilersHouses,
    memoizedDate,
    memoizedSmena
  } = useContext(TControlContext)

  const { handleSubmit, reset } = useForm({ mode: 'onBlur' });

  let [FIO, setFIO] = useState('')

  let [activeTab, setActiveTab] = useState('Показатели за месяц');

  return (
    <div className="container">
      {memoizedSmena?.activeSmena.length === 0 ?
        <div className="RSODynamic_worker_create_smena" style={{ marginTop: '120px' }}>
          <h2 className="title">Создание смены</h2>
          <form className='RSODynamic_worker_create_smena__form' onSubmit={e => handleSubmit(postSmena(authTokens, { fullname: FIO }, e))}>
            <TControlInput
              type='string'
              title='Введите ФИО'
              name='enterFIO'
              setter={setFIO}
              getter={FIO}
              value={FIO}
              required={true}
            />
            {FIO ? (
              <div style={{ margin: "auto" }}>
                <TControlButton
                  title='Сохранить'
                  type='submit' />
              </div>
            ) : ("")}
          </form>
        </div>
        : 
        <div className="container" style={{marginTop: '120px'}}>
          <RSODynamicButtonGroup
            buttons={[
              { title: 'Показатели за месяц' },
              ...(memoizedSmena?.activeSmena?.[0]?.shift_number == 1 ? [{ title: 'Ввод дневных показаний' }] : []),
              { title: 'Опер. параметры' },
              { title: 'Ввод опер. параметров' },
              { title: 'Закрытие смены' },
            ]}
            setter={setActiveTab}
            getter={activeTab} />

          {activeTab === "Показатели за месяц" &&
            <PrintMonthData />}
          {activeTab === "Ввод дневных показаний" &&
            <DayData />}
          {activeTab === "Опер. параметры" &&
            <OperParam />}
          {activeTab === "Ввод опер. параметров" &&
            <InputOperParam />}
          {activeTab === "Закрытие смены" &&
            <CloseSmena />}
        </div>
      }


    </div>
  )
}