import React, { useState, useEffect, useContext } from "react";
import "../PublicHouse.css";

import { API_URL } from "../../../../../../../../config";
import AuthContext from "../../../../../../../../context/AuthContext";

export const PublicWindow = ({ formDataPublic, onDataChange }) => {
  const { authTokens } = useContext(AuthContext);
  const [windowRecords, setWindowRecords] = useState([]); // Записи окон
  const [selectedSize, setSelectedSize] = useState(""); // Выбранный размер
  const [customWidth, setCustomWidth] = useState(""); // Ширина проема
  const [customHeight, setCustomHeight] = useState(""); // Высота проема
  const [quantity, setQuantity] = useState(""); // Количество окон
  const [totalArea, setTotalArea] = useState(0); // Общая площадь
  const [position, setPosition] = useState(""); // Позиция

  const idHouse = localStorage.getItem("ID_House");;

  // Обработчики изменения значений
  const handleSelectChange = (e) => {
    setSelectedSize(e.target.value);
    setCustomWidth("");
    setCustomHeight("");
  };

  const handleWidthChange = (e) => {
    setCustomWidth(e.target.value);
    setSelectedSize("custom");
  };

  const handleHeightChange = (e) => {
    setCustomHeight(e.target.value);
    setSelectedSize("custom");
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  // Обработка добавления окна
  const handleAddWindowRecord = async () => {
    let width, height;

    // Выбор размера окна (стандартный или произвольный)
    if (selectedSize !== "custom") {
      [width, height] = selectedSize.split("x").map(Number);
    } else {
      width = parseFloat(customWidth);
      height = parseFloat(customHeight);
    }

    const areaPerWindow = (width * height) / 1000000; // Площадь одного окна в м²
    const windowArea = areaPerWindow * quantity; // Общая площадь для этой записи

    const dataToSend = {
      position: position,
      height: height,
      width: width,
      count: parseInt(quantity, 10),
      idHouse: idHouse,
    };

    console.log(dataToSend);

    try {
      const response = await fetch(`${API_URL}/CivilWear/Window/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error("Ошибка отправки данных");
      }

      // Добавляем новую запись в таблицу только после успешной отправки
      const newRecord = {
        size:
          selectedSize !== "custom"
            ? `${width} × ${height} мм`
            : `Произвольный: ${width} × ${height} мм`,
        quantity: parseInt(quantity, 10),
        totalArea: windowArea.toFixed(2) + " м²", // Общая площадь с округлением
      };

      const updatedRecords = [...windowRecords, newRecord];
      setWindowRecords(updatedRecords);

      // Обновляем общую площадь
      const newTotalArea = updatedRecords.reduce(
        (sum, record) => sum + parseFloat(record.totalArea),
        0
      );
      setTotalArea(newTotalArea.toFixed(2) + " м²");

      // Обновляем formDataPublic
      onDataChange({
        ...formDataPublic,
        totalareawindow: newTotalArea.toFixed(2), // Записываем значение в formDataPublic
      });

      console.log("Данные успешно отправлены");
    } catch (error) {
      console.error("Ошибка при отправке:", error);
    }

    // Сброс полей ввода
    setSelectedSize("");
    setCustomWidth("");
    setCustomHeight("");
    setQuantity("");
    setPosition("");
  };

  useEffect(() => {
    if (formDataPublic.totalareawindow) {
      setTotalArea(formDataPublic.totalareawindow);
    }
  }, [formDataPublic]);

  return (
    <div className="Public_Window_Main">
      <div className="Public_Window_Input_Field_Group">
        <label className="Public_Window_Label" htmlFor="windowSize">
          Размер оконного проема:
        </label>
        <select
          className="Public_Window_Select_Field"
          id="windowSize"
          value={selectedSize}
          onChange={handleSelectChange}
        >
          <option value="">Выбрать размер</option>
          <option value="850x1150">850 × 1150 мм</option>
          <option value="1150x1900">1150 × 1900 мм</option>
          <option value="1300x2200">1300 × 2200 мм</option>
          <option value="1450x1500">1450 × 1500 мм</option>
          <option value="2400x2100">2400 × 2100 мм</option>
          <option value="custom">Добавить свой размер</option>
        </select>
      </div>

      {selectedSize === "custom" && (
        <div className="Public_Window_Custom_Size">
          <label className="Public_Window_Label" htmlFor="customWidth">
            Ширина проема (мм):
          </label>
          <input
            className="Public_Window_Input_Field"
            type="number"
            id="customWidth"
            placeholder="Введите ширину"
            value={customWidth}
            onChange={handleWidthChange}
            required
          />

          <label className="Public_Window_Label" htmlFor="customHeight">
            Высота проема (мм):
          </label>
          <input
            className="Public_Window_Input_Field"
            type="number"
            id="customHeight"
            placeholder="Введите высоту"
            value={customHeight}
            onChange={handleHeightChange}
            required
          />
        </div>
      )}

      <div className="Public_Window_Input_Field_Group">
        <label className="Public_Window_Label" htmlFor="quantity">
          Количество оконных проемов:
        </label>
        <input
          className="Public_Window_Input_Field"
          type="number"
          id="quantity"
          placeholder="Введите количество"
          value={quantity}
          onChange={handleQuantityChange}
          required
        />
      </div>

      <div className="Public_Window_Input_Field_Group">
        <label className="Public_Window_Label" htmlFor="position">
          Позиция:
        </label>
        <input
          className="Public_Window_Input_Field"
          type="text"
          id="position"
          placeholder="Введите позицию"
          value={position}
          onChange={handlePositionChange}
          required
        />
      </div>

      <button
        className="Public_Window_Add_Button"
        onClick={handleAddWindowRecord}
      >
        Добавить проем
      </button>

      {/* Таблица с добавленными записями */}
      <div className="Public_Window_Table">
        <h3>Таблица оконных проемов</h3>
        <table>
          <thead>
            <tr>
              <th>Размер оконного проема</th>
              <th>Количество</th>
              <th>Общая площадь (м²)</th>
            </tr>
          </thead>
          <tbody>
            {windowRecords.map((record, index) => (
              <tr key={index}>
                <td>{record.size}</td>
                <td>{record.quantity}</td>
                <td>{record.totalArea}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Отображение общей площади */}
        <div className="Public_Window_Total_Area">
          <h4>Всего:</h4>
          <p>{totalArea}</p>
        </div>
      </div>
    </div>
  );
};
