import React, { useState, useContext, useEffect } from "react";

import { Mussor } from "./ButtonInz/Mussor";
import { Electr } from "./ButtonInz/Electr";
import { GorVod } from "./ButtonInz/GorVod";
import { HolVod } from "./ButtonInz/HolVod";
import { Kanaliz } from "./ButtonInz/Kanaliz";
import { ChentrOtop } from "./ButtonInz/ChentrOtop";


export const Inz = () => {
    const [activeComponent, setActiveComponent] = useState(null);
  
    const renderComponent = () => {
      switch (activeComponent) {
        case "Mussor":
          return <Mussor />;
        case "Electr":
          return <Electr />;
        case "GorVod":
          return <GorVod />;
        case "HolVod":
          return <HolVod />;
        case "Kanaliz":
          return <Kanaliz />;
        case "ChentrOtop":
          return <ChentrOtop />;
        default:
          return null;
      }
    };
  
    return (
      <div className="Inz_container">
        <div className="Inz_buttons">
          <button className="Inz_button" onClick={() => setActiveComponent("Mussor")}>Мусоропровод</button>
          <button className="Inz_button" onClick={() => setActiveComponent("Kanaliz")}>Канализация</button>
          <button className="Inz_button" onClick={() => setActiveComponent("ChentrOtop")}>Центральное отопление</button>
          <button className="Inz_button" onClick={() => setActiveComponent("HolVod")}>Холодное водоснабжение</button>
          <button className="Inz_button" onClick={() => setActiveComponent("GorVod")}>Горячее водоснабжение</button>
          <button className="Inz_button" onClick={() => setActiveComponent("Electr")}>Электроника</button>
        </div>
  
        <div className="ButtonAddWear_content">
          {renderComponent()}
        </div>
      </div>
    );
  };