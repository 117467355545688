import { API_URL } from "../../../config";
import { resetInputFields } from "../../../utils/utils";

export let getBoilersHouses = async (authTokens, setter) => {
  let response = await fetch(`${API_URL}/TControl/boilerhouse/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return setter(await response.json());
}

export let getBoilersHouseBoiler = async (authTokens, setter) => {
  let response = await fetch(`${API_URL}/TControl/boiler/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return setter(await response.json());
}

export let getBoilersHousePump = async (authTokens, setter) => {
  let response = await fetch(`${API_URL}/TControl/boilerhousepump/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return setter(await response.json());
}


export let getActiveSmena = async (authTokens, setter) => {
  let response = await fetch(API_URL + '/TControl/smena/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
  });
  return setter(await response.json());
}


export let postSmena = async (authTokens, data, e) => {
  if (e !== undefined) { e.preventDefault() }
  let response = await fetch(API_URL + '/TControl/smena/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
    body: JSON.stringify({
      ...data
    }),
  });

  if (response.status) {
    console.log('🥶🥶🥶');

    document.location.reload()
  }
}


export let getMonthData = async (authTokens, parametr, date, setter) => {
  let response = await fetch(API_URL + `/TControl/smena/get/${parametr}/parameters/${date}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
  });
  return setter(await response.json());
}

export let getSmenaData = async (authTokens, parametr, boilerhouse_id, smenaId, date, setter) => {
  let response = await fetch(API_URL + `/TControl/daily_dynamic_settings/get/${parametr}/parameters/${date}/?boilerhouse_id=${boilerhouse_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
  });
  return setter(await response.json());
} 


export let getTodayData = async (authTokens, smena_id, setter) => {
  let response = await fetch(API_URL + `/TControl/daily_dynamic_settings/smena/${smena_id}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
  });
  return setter(await response.json());
} 


export let postDailyRates = async (authTokens, data, boilerhouse_id, smenaId, setter) => {
  // e.preventDefault();
  let exportData = {
    electricity_consumption: data.electricity_consumption,
    gas_consumption: data.gas_consumption,
    gas_consumption_time: `${data?.gas_consumption_time_hours}:${data?.gas_consumption_time_minutes}:00`,
    water_consumption: data.water_consumption,
    water_consumption_time: `${data?.water_consumption_time_hours}:${data?.water_consumption_time_minutes}:00`
  };

  // Условно добавляем поле electricity_consumption_time, если данные для времени работы электричества заданы
  if (data?.electricity_consumption_time_hours != null || data?.electricity_consumption_time_minutes != null) {
    exportData.electricity_consumption_time = `${String(data.electricity_consumption_time_hours).padStart(2, '0')}:${String(data?.electricity_consumption_time_minutes).padStart(2, '0')}:00`;
  }
  
  let response = await fetch(`${API_URL}/TControl/daily_dynamic_settings/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({
      ...exportData,
      smena_id: smenaId,
      boilerhouse_id: boilerhouse_id
    })
  });
  if (response.status === 201) {
    //Очистка хука с данными
    setter({})
    document.location.reload()
    // let arrNames = ["electricity_consumption_time_hours", "electricity_consumption_time_minutes", "gas_consumption", "gas_consumption_time_hours", "gas_consumption_time_minutes", "water_consumption", "electricity_consumption", "max_consumption", "water_consumption_time_hours", "water_consumption_time_minutes"]
    // arrNames.forEach(name => {
    //   resetInputFields(name);
    // });
  }
}

export let patchDailyRates = async (authTokens, id, data, boilerhouse_id, smenaId, setter) => {
  let exportData = {
    electricity_consumption: data.electricity_consumption,
    gas_consumption: data.gas_consumption,
    gas_consumption_time: `${data?.gas_consumption_time_hours}:${data?.gas_consumption_time_minutes}:00`,
    water_consumption: data.water_consumption,
    water_consumption_time: `${data?.water_consumption_time_hours}:${data?.water_consumption_time_minutes}:00`
  };

  // Условно добавляем поле electricity_consumption_time, если данные для времени работы электричества заданы
  if (data?.electricity_consumption_time_hours != null || data?.electricity_consumption_time_minutes != null) {
    exportData.electricity_consumption_time = `${String(data.electricity_consumption_time_hours).padStart(2, '0')}:${String(data?.electricity_consumption_time_minutes).padStart(2, '0')}:00`;
  }

  let response = await fetch(`${API_URL}/TControl/daily_dynamic_settings/${id}/`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({
      ...exportData,
      smena_id: smenaId,
      boilerhouse_id: boilerhouse_id
    })
  });

  if (response.status === 200) {
    // Очистка хука с данными
    setter({});
    document.location.reload()
    // let arrNames = ["electricity_consumption_time_hours", "electricity_consumption_time_minutes", "gas_consumption", "gas_consumption_time_hours", "gas_consumption_time_minutes", "water_consumption", "electricity_consumption", "max_consumption", "water_consumption_time_hours", "water_consumption_time_minutes"]
    // arrNames.forEach(name => {
    //   resetInputFields(name);
    // });
  }
};


export let closeSmena = async (e, authTokens, smenaId, setLoading) => {
  e.preventDefault();
  setLoading(true)

  let response = await fetch(`${API_URL}/TControl/smena/${smenaId}/`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({ flag_complete: true }),
  });

  if (response.status === 200) {
    setLoading(false)
    document.location.reload()
  }
};


export let getDynamicValuesSmena = async (authTokens, smenaId, setter) => {
  let response = await fetch(API_URL + `/TControl/dynamic_settings/smena/${smenaId}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
  });
  return setter(await response.json());
}


export let postDynamicValues = async (authTokens, data, smenaId, boilerhouseId) => {
  let response = await fetch(API_URL + '/TControl/dynamic_settings/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
    body: JSON.stringify({
      ...data,
      smena_id: smenaId,
      boilerhouse_id: boilerhouseId
    }),
  });

  if (response.status) {
    document.location.reload()
  }
}