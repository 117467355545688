import React, { useState, useEffect, useContext } from "react";
import { PublicData } from "./component/PublicData";
import { PublicConstr } from "./component/PublicConstr";
import { PublicInz } from "./component/PublicInz";
import { PublicOption } from "./component/PublicOption";
import { PublicWindow } from "./component/PublicWindow";
import { PublicDoor } from "./component/PublicDoor";

import { NextAndPrefButton } from "./component/Button/NextAndPrefButton";
import { AddDatatoHome } from "./component/Button/AddDatatoHome";
import { PrefButton } from "./component/Button/PrefButton";
import { NextButton } from "./component/Button/NextButton";
import { ExitButton } from "./component/Button/ExitButton";

import { API_URL } from "../../../../../../../config";
import AuthContext from "../../../../../../../context/AuthContext";

import "./PublicHouse.css";

export const PublicHouse = () => {
  const type_in_programm = 2; //Тип здания Общественное

  const min_page = 1;
  const max_page = 6;

  const { authTokens } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [formDataPublic, setformDataPublic] = useState({
    type_in_programm: type_in_programm, //Тип здания (Жилое/Общественное) int
    address: "", // Адрес string
    year: "", // Год ввода в эксплуатацию int
    floors: "", // Этажность int
    underground_floors: "", // Кол-во подземных этажей int
    building_area: "", // Площадь здания float
    organization: "", // Организация, управляющая МКД string
    construction_year: "", // Год постройки int
    lifecycle_stage: "", // Стадия жизненного цикла int
    appointment: "", //Назначение int
    reconstruction_year: "", // Год проведения реконструкции int
    apartment_count: "", // Кол-во квартир int
    apartments_per_entrance: "", // Кол-во квартир в подъезде int
    building_volume: "", // Объем здания float
    building_width: "", // Ширина здания float
    building_length: "", // Длина здания float
    wall_height: "", // Высота стен float
    number_window_openings: "", // Количество оконных проемов int
    varied_window_count: "", //Количество разноразмерных оконных проемов int
    basement: false, // Подвал bool
    annex: false, // Пристройка bool
    attic: false, // Мансарда bool
    sewage: false, // Канализация bool
    trash_chute: false, // Мусоропровод bool
    heating: false, // Центральное отопление bool
    cold_water: false, // Холодное водоснабжение bool
    hot_water: false, // Горячее водоснабжение bool
    electrical: false, // Электропроводка bool
    elevator: false, // Лифт bool
    foundation_walls_area: "", // Площадь стен фундамента float
    balcony_area: "", //Общая площадь балконов float
    building_walls_area: "", //Площадь стен здания float
    load_bearing_area: "", // Общая площадь несущих конструкций float
    floor_area: "", // Общая площадь перекрытия float
    roof_area: "", // Общая плозщадь крыши float
    balcony_count: "", // Кол-во балконов int
    loggia_count: "", // Кол-во лоджий int
    canopy_count: "", //Кол-во козырьков int
    bay_window_count: "", //Кол-во эркеров int
    hot_water_system: "", // Система горячего водоснабжения float
    central_heating_system: "", // Система центрального водоснабжения float
    cold_water_system: "", // Система холодного водоснабжения float
    sewage_system: "", // Система канализаций float
    electrical_system: "", // Система электрооборудования float
    trash_chutes: "", //Мусоропроводы float
    foundationType: "", // Тип фундамента int
    wallType: "", // Тип стен int
    floorType: "", // Тип перекрытий int
    roofType: "", // Тип крыши int
    roofingType: "", // Тип кровли int
    balconyType: "", // Тип балконов int
    totalareawindow: "", // Общая площаль оконных проемов float
    totalaredoor: "", // Общая площадь дверных проемов float
    buildingType: "", // Тип здания int
  });

  //Функция сброса значений
  const resetFormDataPublic = () => {
    setformDataPublic({
      type_in_programm: type_in_programm, // Тип здания (Жилое/Общественное) int
      address: "", // Адрес string
      year: "", // Год ввода в эксплуатацию int
      floors: "", // Этажность int
      underground_floors: "", // Кол-во подземных этажей int
      building_area: "", // Площадь здания float
      organization: "", // Организация, управляющая МКД string
      construction_year: "", // Год постройки int
      lifecycle_stage: "", // Стадия жизненного цикла int
      appointment: "", // Назначение int
      reconstruction_year: "", // Год проведения реконструкции int
      apartment_count: "", // Кол-во квартир int
      apartments_per_entrance: "", // Кол-во квартир в подъезде int
      building_volume: "", // Объем здания float
      building_width: "", // Ширина здания float
      building_length: "", // Длина здания float
      wall_height: "", // Высота стен float
      number_window_openings: "", // Количество оконных проемов int
      varied_window_count: "", // Количество разноразмерных оконных проемов int
      basement: false, // Подвал bool
      annex: false, // Пристройка bool
      attic: false, // Мансарда bool
      sewage: false, // Канализация bool
      trash_chute: false, // Мусоропровод bool
      heating: false, // Центральное отопление bool
      cold_water: false, // Холодное водоснабжение bool
      hot_water: false, // Горячее водоснабжение bool
      electrical: false, // Электропроводка bool
      elevator: false, // Лифт bool
      foundation_walls_area: "", // Площадь стен фундамента float
      balcony_area: "", // Общая площадь балконов float
      building_walls_area: "", // Площадь стен здания float
      load_bearing_area: "", // Общая площадь несущих конструкций float
      floor_area: "", // Общая площадь перекрытия float
      roof_area: "", // Общая площадь крыши float
      balcony_count: "", // Кол-во балконов int
      loggia_count: "", // Кол-во лоджий int
      canopy_count: "", // Кол-во козырьков int
      bay_window_count: "", // Кол-во эркеров int
      hot_water_system: "", // Система горячего водоснабжения float
      central_heating_system: "", // Система центрального водоснабжения float
      cold_water_system: "", // Система холодного водоснабжения float
      sewage_system: "", // Система канализаций float
      electrical_system: "", // Система электрооборудования float
      trash_chutes: "", // Мусоропроводы float
      foundationType: "", // Тип фундамента int
      wallType: "", // Тип стен int
      floorType: "", // Тип перекрытий int
      roofType: "", // Тип крыши int
      roofingType: "", // Тип кровли int
      balconyType: "", // Тип балконов int
      totalareawindow: "", // Общая площадь оконных проемов float
      totalaredoor: "", // Общая площадь дверных проемов float
      buildingType: "", // Тип здания int
    });
  };

  //Отрисовка компонентов
  const renderComponent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <PublicData
              formDataPublic={formDataPublic}
              onDataChange={handleDataChange}
            />
            <NextButton handleStepChange={handleStepChange} />
          </>
        );
      case 2:
        return (
          <>
            <PublicOption
              formDataPublic={formDataPublic}
              onDataChange={handleDataChange}
            />
            <NextAndPrefButton handleStepChange={handleStepChange} />
          </>
        );
      case 3:
        return (
          <>
            <PublicConstr
              formDataPublic={formDataPublic}
              onDataChange={handleDataChange}
            />
            <NextAndPrefButton handleStepChange={handleStepChange} />
          </>
        );
      case 4:
        return (
          <>
            <PublicInz
              formDataPublic={formDataPublic}
              onDataChange={handleDataChange}
            />
            <div className="Button_to_Inz_AddHouse">
              <PrefButton handleStepChange={handleStepChange} />
              <AddDatatoHome
                formDataPublic={formDataPublic}
                handleAddDataPublicHouse={handleAddDataPublicHouse}
                handleStepChange={handleStepChange}
                resetFormDataPublic={resetFormDataPublic}
              />
            </div>
          </>
        );
      case 5:
        return (
          <>
            <PublicWindow
              formDataPublic={formDataPublic}
              onDataChange={handleDataChange}
            ></PublicWindow>
            <NextButton handleStepChange={handleStepChange} />
          </>
        );
      case 6:
        return (
          <>
            <PublicDoor
              formDataPublic={formDataPublic}
              onDataChange={handleDataChange}
            ></PublicDoor>
            <ExitButton handleStepChange={handleStepChange} />
          </>
        );
      default:
        return (
          <>
            <PublicData
              formDataPublic={formDataPublic}
              onDataChange={handleDataChange}
            />
            <NextAndPrefButton handleStepChange={handleStepChange} />
          </>
        );
    }
  };

  //Логика перемещения по страницам
  const handleStepChange = (direction) => {
    if (direction === "next" && step < max_page) {
      setStep(step + 1);
    } else if (direction === "prev" && step > min_page) {
      setStep(step - 1);
    } else if (direction === "exit") {
      setStep(1);
    }
  };

  //Сохрание данных о здании в состоянии
  const handleDataChange = (updatedData) => {
    setformDataPublic((prevData) => ({ ...prevData, ...updatedData }));
  };

  //Автоматический подсчет объема здания
  useEffect(() => {
    const update_building_volume = () => {
      const newValue =
        parseInt(formDataPublic.building_width) *
        parseInt(formDataPublic.building_length) *
        parseInt(formDataPublic.wall_height);
      const roundedValue = newValue.toFixed(2);
      setformDataPublic((prevState) => ({
        ...prevState,
        building_volume: roundedValue.toString(),
      }));
    };

    update_building_volume();
  }, [
    formDataPublic.building_width,
    formDataPublic.building_length,
    formDataPublic.wall_height,
  ]);

  //Автоматический подсчет площади здания
  useEffect(() => {
    const update_building_area = () => {
      const newValue =
        parseInt(formDataPublic.building_width) *
        parseInt(formDataPublic.building_length);
      const roundedValue = newValue.toFixed(2);
      setformDataPublic((prevState) => ({
        ...prevState,
        building_area: roundedValue.toString(),
      }));
    };

    update_building_area();
  }, [formDataPublic.building_width, formDataPublic.building_length]);

  //Получение id по адресу
  const getHouseIdByAddress = () => {
    const address = formDataPublic.address;

    // Запрос для получения ID по выбранному адресу
    fetch(`${API_URL}/CivilWear/House/findHouseIdByAddress/${address}/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authTokens.access}`, // Добавление токена в заголовок
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const houseId = data.id; // Получаем ID здания
        localStorage.setItem("ID_House", houseId); // Сохраняем ID здания в localStorage

        console.log(`Выбранный адрес: ${address}`); // Выводим выбранный адрес в консоль
        console.log(`ID здания: ${houseId}`); // Выводим ID здания в консоль
      })
      .catch((error) => {
        console.error("Ошибка при получении ID здания:", error);
        alert("Произошла ошибка при получении ID здания.");
      });
  };

  //Добавление здания
  const handleAddDataPublicHouse = async () => {
    try {
      const data = {
        BuildingType: parseInt(formDataPublic.type_in_programm), // Тип здания (integer)
        yearOperation: formDataPublic.year, // Год введения в эксплуатацию (string)
        address: formDataPublic.address, // Адрес (string)
        houseArea: parseFloat(formDataPublic.building_area), // Площадь дома (number)
        floorCount: parseInt(formDataPublic.floors), // Количество этажей (integer)
        undergroundFloorCount: parseInt(formDataPublic.underground_floors), // Количество подземных этажей (integer)
        BuildingArea: parseFloat(formDataPublic.building_area), // Площадь здания (number)
        ConstractionYear: parseInt(formDataPublic.construction_year), // Год постройки (integer)
        foundationType: parseInt(formDataPublic.foundationType), // Тип фундамента (integer)
        wallType: parseInt(formDataPublic.wallType), // Тип стен (integer)
        floorType: parseInt(formDataPublic.floorType), // Тип пола (integer)
        balconyType: parseInt(formDataPublic.balconyType), // Тип балкона (integer)
        roofType: parseInt(formDataPublic.roofType), // Тип крыши (integer)
        roofingType: parseInt(formDataPublic.roofingType), // Тип кровли (integer)
        foundationVolume: parseFloat(formDataPublic.foundation_walls_area), // Объем фундамента (float)
        wallVolume: parseFloat(formDataPublic.building_walls_area), // Объем стен (float)
        floorVolume: parseFloat(formDataPublic.floor_area), // Объем пола (float)
        balconyVolume: parseFloat(formDataPublic.balcony_area), // Объем балкона (float)
        roofVolume: parseFloat(formDataPublic.roof_area), // Объем крыши (float)
        roofingVolume: 0, // Не указано в formDataPublic, возможно нужно изменить в дальнейшем
        sewerageLength: parseFloat(formDataPublic.sewage_system), // Длина канализации (float)
        chuteLength: parseFloat(formDataPublic.trash_chutes), // Длина мусоропровода (float)
        centralHeatingLength: parseFloat(formDataPublic.central_heating_system), // Длина центрального отопления (float)
        coldWaterSupplyLength: parseFloat(formDataPublic.cold_water_system), // Длина холодного водоснабжения (float)
        hotWaterSupplyLength: parseFloat(formDataPublic.hot_water_system), // Длина горячего водоснабжения (float)
        wiringLength: parseFloat(formDataPublic.electrical_system), // Длина проводки (float)
        basement: formDataPublic.basement, // Подвал (boolean)
        outbuilding: formDataPublic.annex, // Пристройка (boolean)
        attic: formDataPublic.attic, // Мансарда (boolean)
        sewerage: formDataPublic.sewage, // Канализация (boolean)
        elevator: formDataPublic.elevator, // Лифт (boolean)
        GarbageChute: formDataPublic.trash_chute, // Мусоропровод (boolean)
        CentralHeating: formDataPublic.heating, // Центральное отопление (boolean)
        CentralColdWatterSupply: formDataPublic.cold_water, // Холодное водоснабжение (boolean)
        CentralHotWatterSupply: formDataPublic.hot_water, // Горячее водоснабжение (boolean)
        ElectricalWiring: formDataPublic.electrical, // Электропроводка (boolean)
        balconyCount: parseInt(formDataPublic.balcony_count), // Количество балконов (integer)
        lodjiCount: parseInt(formDataPublic.loggia_count), // Количество лоджий (integer)
        canopyCount: parseInt(formDataPublic.canopy_count), // Количество козырьков (integer)
        orielCount: parseInt(formDataPublic.bay_window_count), // Количество эркеров (integer)
        organizationMKD: formDataPublic.organization, // Организация, осуществляющая управление МКД (string)
        yearConstruction: formDataPublic.construction_year, // Год постройки (string)
        lifeStage: parseInt(formDataPublic.lifecycle_stage), // Стадия жизненного цикла (integer)
        yearReconstruction: formDataPublic.reconstruction_year, // Год проведения реконструкции (string)
        numbeApartments: parseInt(formDataPublic.apartment_count), // Количество квартир (integer)
        numbeApartmentsPodezd: parseInt(formDataPublic.apartments_per_entrance), // Количество квартир в подъезде (integer)
        buildingVolume: parseInt(formDataPublic.building_volume), // Объем здания (float)
        buildingWidth: parseFloat(formDataPublic.building_width), // Ширина здания (float)
        buildingLength: parseFloat(formDataPublic.building_length), // Длина здания (float)
        WallHeight: parseFloat(formDataPublic.wall_height), // Высота стен (float)
        FoundationWallsArea: parseFloat(formDataPublic.foundation_walls_area), // Площадь стен фундамента (float)
        BalconyaArea: parseFloat(formDataPublic.balcony_area), // Общая площадь балконов (float)
        BuildingWallsArea: parseFloat(formDataPublic.building_walls_area), // Площадь стен здания (float)
        LoadBearingArea: parseFloat(formDataPublic.load_bearing_area), // Общая площадь несущих конструкций (float)
        FloorArea: parseFloat(formDataPublic.floor_area), // Общая площадь перекрытия (float)
        RoofArea: parseFloat(formDataPublic.roof_area), // Общая площадь крыши (float)
        HotWaterSystem: parseFloat(formDataPublic.hot_water_system), // Система горячего водоснабжения (float)
        CentralHeatingSystem: parseFloat(formDataPublic.central_heating_system), // Система центрального отопления (float)
        ColdWaterSystem: parseFloat(formDataPublic.cold_water_system), // Система холодного водоснабжения (float)
        SewageSystem: parseFloat(formDataPublic.sewage_system), // Система канализаций (float)
        ElectricalSystem: parseFloat(formDataPublic.electrical_system), // Система электрооборудования (float)
        TrashChutes: parseFloat(formDataPublic.trash_chutes), // Мусоропроводы (float)
      };

      console.log(data);

      const response = await fetch(`${API_URL}/CivilWear/House/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Ошибка при отправке данных: ${errorText}`);
      }

      const result = await response.json();
      console.log("Успешно отправлено:", result);
      alert("Здание успешно добавлено в базу ! Осталось совсем немного");
      getHouseIdByAddress();
      resetFormDataPublic();
      handleStepChange("next");
    } catch (error) {
      console.error("Ошибка при отправке:", error);
      alert("Произошла ошибка, проверьте данные !");
    }
  };

  return <div>{renderComponent()}</div>;
};
