import React, { useState, useEffect } from "react";
import { AddressSuggestions } from 'react-dadata';

import "../PublicHouse.css";

export const PublicData = ({ formDataPublic, onDataChange }) => {

  // Прокрутка вверх при монтировании компонента
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
      });
    }, []); // Пустой массив зависимостей, чтобы эффект срабатывал только при монтировании компонента

  //Считывание данных из полей для ввода и выпадающих списков
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    onDataChange({ ...formDataPublic, [id]: value });
  };

  //Считывание данных из чекбоксов
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    onDataChange({ ...formDataPublic, [id]: checked });
  };

  // Обработка изменения адреса
  const handleAddressChange = (value) => {
    onDataChange({ ...formDataPublic, address: value });
  };

  return (
    <div className="Public_Data_Main">
      <form>
        <div className="Public_Data_Inputs">
          <div className="Public_Data_Input_Field_Group">
          <AddressSuggestions
              token="6dc7b29f2b8e6eb9bc537838a324af7caafc5eca"
              value={formDataPublic.address}
              onChange={(suggestion) => handleAddressChange(suggestion.value)}
              inputProps={{
                className: "Public_Data_Input_Field_Address",
                placeholder: "Адрес"
              }}
            />
            <input
              className="Public_Data_Input_Field"
              type="number"
              id="year"
              placeholder="Год ввода в эксплуатацию"
              required
              value={formDataPublic.year || ''}
              onChange={handleInputChange}
            />
            <input
              className="Public_Data_Input_Field"
              type="number"
              id="floors"
              placeholder="Этажность"
              required
              value={formDataPublic.floors || ''}
              onChange={handleInputChange}
            />
            <input
              className="Public_Data_Input_Field"
              type="number"
              id="underground_floors"
              placeholder="Количество подземных этажей"
              required
              value={formDataPublic.underground_floors || ''}
              onChange={handleInputChange}
            />
            <input
              className="Public_Data_Input_Field"
              type="number"
              id="construction_year"
              placeholder="Год постройки"
              required
              value={formDataPublic.construction_year || ''}
              onChange={handleInputChange}
            />
            <select
              id="lifecycle_stage"
              className="Public_Data_Input_Field"
              required
              value={formDataPublic.lifecycle_stage || ''}
              onChange={handleInputChange}
            >
              <option value="" disabled>Стадия жизненного цикла</option>
              <option value="1">Эксплуатация</option>
              <option value="2">Модернизация</option>
              <option value="3">Реконструкция</option>
              <option value="4">Капитальный ремонт</option>
            </select>
            <select
              id="appointment"
              className="Public_Data_Input_Field"
              required
              value={formDataPublic.appointment || ''}
              onChange={handleInputChange}
            >
              <option value="" disabled>Назначение</option>
              <option value="1">Административное</option>
              <option value="2">Образовательное</option>
              <option value="3">Медицинское</option>
            </select>
            <input
              className="Public_Data_Input_Field"
              type="number"
              id="reconstruction_year"
              placeholder="Год проведения реконструкции"
              required
              value={formDataPublic.reconstruction_year || ''}
              onChange={handleInputChange}
            />
            <input
              className="Public_Data_Input_Field"
              type="number"
              id="apartment_count"
              placeholder="Количество квартир"
              required
              value={formDataPublic.apartment_count || ''}
              onChange={handleInputChange}
            />
            <input
              className="Public_Data_Input_Field"
              type="number"
              id="apartments_per_entrance"
              placeholder="Количество квартир в подъезде"
              required
              value={formDataPublic.apartments_per_entrance || ''}
              onChange={handleInputChange}
            />
              <input
              className="Public_Data_Input_Field"
              type="number"
              id="building_width"
              placeholder="Ширина здания"
              required
              value={formDataPublic.building_width || ''}
              onChange={handleInputChange}
            />
              <input
              className="Public_Data_Input_Field"
              type="number"
              id="building_length"
              placeholder="Длина здания"
              required
              value={formDataPublic.building_length || ''}
              onChange={handleInputChange}
            />
              <input
              className="Public_Data_Input_Field"
              type="number"
              id="wall_height"
              placeholder="Высота стены"
              required
              value={formDataPublic.wall_height || ''}
              onChange={handleInputChange}
            />
            <input
              className="Public_Data_Input_Field"
              type="number"
              id="number_window_openings"
              placeholder="Количество оконных проемов"
              required
              value={formDataPublic.number_window_openings || ''}
              onChange={handleInputChange}
            />
            <input
              className="Public_Data_Input_Field"
              type="number"
              id="building_volume"
              placeholder="Объем здания"
              required
              value={formDataPublic.building_volume || ''}
              onChange={handleInputChange}
              readOnly
            />
            <input
              className="Public_Data_Input_Field"
              type="number"
              id="building_area"
              placeholder="Площадь здания"
              required
              value={formDataPublic.building_area || ''}
              onChange={handleInputChange}
              readOnly
            />
            <input
              className="Public_Data_Input_Field"
              type="text"
              id="organization"
              placeholder="Организация, осуществляющая управление зданием"
              required
              value={formDataPublic.organization || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Public_Data_Section_Title">
          <span className="Public_Data_Section_Span">Оборудование и благоустройство</span>
        </div>
        <div className="Public_Data_Checkbox_Group">
          <label className="Public_Data_Checkbox_Label" htmlFor="basement">
            Подвал
          </label>
          <input 
            className="Public_Data_Checkbox" 
            type="checkbox" 
            id="basement"
            checked={formDataPublic.basement || false}
            onChange={handleCheckboxChange}
          />
          <label className="Public_Data_Checkbox_Label" htmlFor="annex">
            Пристройка
          </label>
          <input 
            className="Public_Data_Checkbox" 
            type="checkbox" 
            id="annex"
            checked={formDataPublic.annex || false}
            onChange={handleCheckboxChange}
          />
          <label className="Public_Data_Checkbox_Label" htmlFor="attic">
            Мансарда
          </label>
          <input 
            className="Public_Data_Checkbox" 
            type="checkbox" 
            id="attic"
            checked={formDataPublic.attic || false}
            onChange={handleCheckboxChange}
          />
          <label className="Public_Data_Checkbox_Label" htmlFor="sewage">
            Канализация
          </label>
          <input 
            className="Public_Data_Checkbox" 
            type="checkbox" 
            id="sewage"
            checked={formDataPublic.sewage || false}
            onChange={handleCheckboxChange}
          />
          <label className="Public_Data_Checkbox_Label" htmlFor="trash_chute">
            Мусоропровод
          </label>
          <input 
            className="Public_Data_Checkbox" 
            type="checkbox" 
            id="trash_chute"
            checked={formDataPublic.trash_chute || false}
            onChange={handleCheckboxChange}
          />
          <label className="Public_Data_Checkbox_Label" htmlFor="heating">
            Центральное отопление
          </label>
          <input 
            className="Public_Data_Checkbox" 
            type="checkbox" 
            id="heating"
            checked={formDataPublic.heating || false}
            onChange={handleCheckboxChange}
          />
          <label className="Public_Data_Checkbox_Label" htmlFor="cold_water">
            Холодное водоснабжение
          </label>
          <input 
            className="Public_Data_Checkbox" 
            type="checkbox" 
            id="cold_water"
            checked={formDataPublic.cold_water || false}
            onChange={handleCheckboxChange}
          />
          <label className="Public_Data_Checkbox_Label" htmlFor="hot_water">
            Горячее водоснабжение
          </label>
          <input 
            className="Public_Data_Checkbox" 
            type="checkbox" 
            id="hot_water"
            checked={formDataPublic.hot_water || false}
            onChange={handleCheckboxChange}
          />
          <label className="Public_Data_Checkbox_Label" htmlFor="electrical">
            Электропроводка
          </label>
          <input 
            className="Public_Data_Checkbox" 
            type="checkbox" 
            id="electrical"
            checked={formDataPublic.electrical || false}
            onChange={handleCheckboxChange}
          />
          <label className="Public_Data_Checkbox_Label" htmlFor="elevator">
            Лифт
          </label>
          <input 
            className="Public_Data_Checkbox" 
            type="checkbox" 
            id="elevator"
            checked={formDataPublic.elevator || false}
            onChange={handleCheckboxChange}
          />
        </div>
      </form>
    </div>
  );
};
