import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../context/AuthContext";
import { TControlContext } from "../../hooks/useTControl";
import { getDynamicValuesSmena } from "../../../../http/http";
import { ModelTable } from "../../../../../../components/ModelTable/ModelTable";
import { TControlSelect } from "../../../../utils/TControlSelect/TControlSelect";

export let OperParam = () => {
  const { authTokens } = useContext(AuthContext);

  let {
    memoizedBoilersHouses,
    memoizedDate,
    memoizedSmena,
    memoizedErenergyResource
  } = useContext(TControlContext)


  const formatDate = (dateStr) => {
    console.log(dateStr);
    
    // Преобразование строки в объект Date
    const dateObj = new Date(dateStr);

    // Форматирование даты
    const formattedDate = dateObj
      .toLocaleString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });

    return <div>{formattedDate}</div>;
  };

  let [dynamicValuesGet, setDynamicValuesGet] = useState([]);

  useEffect(() => {
    getDynamicValuesSmena(authTokens, memoizedSmena?.activeSmena?.[0]?.pk, setDynamicValuesGet)
  }, [memoizedSmena?.activeSmena])

  return (
    <div className="container">
      <TControlSelect
        name={"Выберите котельную"}
        list={memoizedBoilersHouses?.boilersHouses}
        value={'pk'}
        visible_value={'address'}
        getter={memoizedBoilersHouses?.selecterBoilersHouse}
        setter={memoizedBoilersHouses?.setSelecterBoilersHouse}
        parse={'int'}
        defaultValue={memoizedBoilersHouses?.selecterBoilersHouse}
      />
      {dynamicValuesGet.length !== 0 ? (
        <ModelTable>
          <thead>
            <tr>
              <th colSpan='7'>Параметры контроля</th>
            </tr>
            <tr>
              <th colSpan='2'></th>
              {/* <th colSpan='1'>Давление воды на вводе</th> */}
              <th colSpan='2'>Теплоноситель</th>
              <th colSpan='2'>ГВС</th>
              <th colSpan='1'>Давление</th>
            </tr>
            <tr>
              <th>Время/Дата</th>
              <th>Котельная</th>
              {/* <th>ФИО оператора</th>
              <th>ФИО передающего</th> */}
              {/* <th>Pвх</th> */}
              <th>T1</th>
              {/* <th>P1</th> */}
              <th>T2</th>
              {/* <th>P2</th> */}
              <th>T3</th>
              {/* <th>P3</th> */}
              <th>T4</th>
              {/* <th>P4</th> */}
              <th>D</th>
            </tr>
          </thead>
          <tbody>
            {dynamicValuesGet.map(item => {
              const matchingBoilerhouse = memoizedBoilersHouses?.boilersHouses.find(item1 => item.boilerhouse_id === item1.pk && item1.pk === memoizedBoilersHouses?.selecterBoilersHouse);

              if (matchingBoilerhouse) {
                return (
                  <tr key={item.id}>
                    <td>{formatDate(item.created_at)}</td>
                    <td>{matchingBoilerhouse.address}</td>
                    {/* <td>{memoizedSmena?.activeSmena.fullname}</td>
                    <td>пока пусто</td> */}
                    {/* <td>{item.pressure}</td> */}
                    <td>{item.t1}</td>
                    {/* <td>{item.p1}</td> */}
                    <td>{item.t2}</td>
                    {/* <td>{item.p2}</td> */}
                    <td>{item.t3}</td>
                    {/* <td>{item.p3}</td> */}
                    <td>{item.t4}</td>
                    {/* <td>{item.p4}</td> */}
                    <td>{item.gas}</td>
                  </tr>
                );
              }
              return null; // Если нет соответствия, возвращаем null или пустой фрагмент
            })}
          </tbody>
        </ModelTable>
      ) : ("")}
    </div>
  )
}