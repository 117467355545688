import React, { useState, useContext, useEffect } from "react";

import { API_URL } from "../../../../../../../../config";
import AuthContext from "../../../../../../../../context/AuthContext";

import "./ButtonInz.css";

export const HolVod = () => {
  const { authTokens } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    plotName: "",
    wear: "",
    area: "",
    photo: null,
  });

  // Определяем переменные
  const currentDate = new Date(); // Получаем текущую дату
  const year = currentDate.getFullYear(); // Получаем год
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Получаем месяц (месяцы начинаются с 0, поэтому добавляем 1) и добавляем 0 спереди
  const day = String(currentDate.getDate()).padStart(2, "0"); // Получаем день и добавляем 0 спереди
  const data_in_wear = `${year}-${month}-${day}`; // Форматируем в строку "YYYY-MM-DD"
  
  const idHouse = localStorage.getItem("ID_House"); // Получаем ID из localStorage

  const element = 9; // Номер элемента

  const elementType = 1; // заменить

  // Функция обнуления значений
  const resetFormData = () => {
    setFormData({
      plotName: "",
      wear: "",
      area: "",
      photo: null,
    });
  };

  //Считывание текста
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //Считывание фото
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      photo: file,
    }));
  };

  // Отправка данных
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      position: formData.plotName,
      data: data_in_wear,
      Element: element,
      volume: formData.area,
      degreeOfDamage: formData.wear,
      idHouse: idHouse,
    };

    try {
      const response = await fetch(`${API_URL}/CivilWear/Damage/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Ошибка при отправке данных: ${errorText}`);
      }

      const result = await response.json();
      console.log("Успешно отправлено:", result);
      alert("Износ добавлен !");
      resetFormData();
    } catch (error) {
      console.error("Ошибка при отправке:", error);
    }
  };

  let data = []; //Данные для таблицы

  switch (elementType) {
    case 1:
      data = [
        ["Признаки износа холодного водоснабжения", "Физический износ, %"],
        [
          "Ослабление сальниковых набивок и прокладок кранов и запорной арматуры, в некоторых смывных бачках имеются утечки воды, повреждение окраски трубопроводов в отдельных местах",
          "0-20",
        ],
        [
          "Капельные течи в местах врезки кранов и запорной арматуры; отдельные повреждения трубопроводов (свищи, течи); поражение коррозией отдельных участков трубопроводов; утечки воды в 20% приборов и смывных бачков",
          "21-40",
        ],
        [
          "Расстройство арматуры и смывных бачков (до 40%); следы ремонта трубопроводов (хомуты, заварка, замена отдельных участков); значительная коррозия трубопроводов; повреждение до 10% смывных бачков (трещины, потеря крышек, рукояток)",
          "41-60",
        ],
        [
          "Полное расстройство системы, выход из строя запорной арматуры, большое количество хомутов, следы замены отдельными местами трубопроводов, большая коррозия элементов системы, повреждение до 30% смывных бачков",
          "61-80",
        ],
      ];

      break;
    default:
      console.log("Ошибка !");
  }

  return (
    <div className="container_Wear_ButtonInz">
      <form className="form_Wear_ButtonInz" onSubmit={handleSubmit}>
        <h3>Холодное водоснабжение:</h3>
        <div className="form-group_Wear_ButtonInz">
          <label htmlFor="plotName" className="label_Wear_ButtonInz">
            Обозначение участка
          </label>
          <input
            className="input_Wear_ButtonInz"
            type="text"
            id="plotName"
            name="plotName"
            value={formData.plotName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonInz">
          <label htmlFor="wear" className="label_Wear_ButtonInz">
            Фактический физический износ участка
          </label>
          <input
            className="input_Wear_ButtonInz"
            type="text"
            id="wear"
            name="wear"
            value={formData.wear}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonInz">
          <label htmlFor="area" className="label_Wear_ButtonInz">
            Площадь участка
          </label>
          <input
            className="input_Wear_ButtonInz"
            type="text"
            id="area"
            name="area"
            value={formData.area}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonInz">
          <label htmlFor="photo" className="label_Wear_ButtonInz">
            Прикрепить фото
          </label>
          <input
            className="input_Wear_ButtonInz"
            type="file"
            id="photo"
            onChange={handlePhotoUpload}
          />
        </div>

        <button type="submit" className="submit-button_Wear_ButtonInz">
          Сохранить данные
        </button>
      </form>

      <div className="table-container_Wear_ButtonInz">
        <table className="table_Wear_ButtonInz">
          <thead>
            <tr>
              {data[0] &&
                data[0].map((header, index) => <th key={index}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
